import { ParsedResponse } from './util';
import {
  Merchant,
  MerchantSettingsInput,
  PaymentParameters,
  UpdateMerchantSettingsMutationVariables,
} from './API/types';
import { generateClient } from 'aws-amplify/api';
import { defaultPaymentParameters, merchant } from './API/graphql/queries';
import { updateMerchantSettings as updateMerchantSettingsMutation } from './API/graphql/mutations';

export const query = async (uid: string): Promise<ParsedResponse<Merchant>> => {
  const client = generateClient();
  const variables = {
    merchant_uid: uid,
  };
  try {
    const response = await client.graphql({
      query: merchant,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.merchant!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch merchant'] };
  }
};

export const paymentParams = async (): Promise<
  ParsedResponse<PaymentParameters>
> => {
  const client = generateClient();
  try {
    const response = await client.graphql({ query: defaultPaymentParameters });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: response.data.defaultPaymentParameters!, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to fetch payment parameters'] };
  }
};

export const updateMerchantSettings = async (
  merchant_uid: string,
  settings: MerchantSettingsInput,
): Promise<ParsedResponse<boolean>> => {
  const client = generateClient();
  const variables: UpdateMerchantSettingsMutationVariables = {
    merchant_uid,
    settings,
  };
  try {
    const response = await client.graphql({
      query: updateMerchantSettingsMutation,
      variables: variables,
    });
    if (response.errors) {
      return { data: null, errors: response.errors.map(err => err.message) };
    }
    return { data: true, errors: null };
  } catch (e) {
    console.log(e);
    return { data: null, errors: ['Unable to update merchant settings'] };
  }
};
